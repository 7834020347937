.device-details-wrapper div{
    margin-bottom: 8px;
}

.refresh-btn {
    position: absolute;
    z-index: 3;
    right: 8px;
    top: 8px;
}

#left-device-list .p-datatable{
    margin-top: 50px;
}