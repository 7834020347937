.layout-mainpage {
    background-color: whitesmoke;

    .side-table {
        .p-datatable {
            margin: 0;
        }
    }
}

.custom-chip {
    &.status-confirmed
    {
        background: green;
        color: white;
    }
    &.status-pending
    {
        background: lightgrey;
    }
    &.status-sent
    {
        background: yellow;
    }
}

#map-position-marker {
    float: left;
    width: 30px;
    height: 30px;
    shape-outside: circle();
    clip-path: circle();
    background: blue;
    opacity: 0.4;
    cursor: none;
}

.app-loading img{
    width: 20%;
}

:root {
    --navbar-color: #FCC535 !important;
}

.navbar .middle-part .p-button.active {
    border-bottom: 4px solid #3C4160;
}